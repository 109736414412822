.alert {
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  border: none;
  transition: all 0.5s;
  min-height: 40px;
  padding: 0 16px;

  &--primary {
    @apply text-primary bg-primary-light;
  }

  &--secondary {
    @apply text-secondary bg-secondary-light;
  }

  &--tertiary {
    @apply text-tertiary bg-tertiary-light;
  }

  &--success {
    @apply text-success bg-success-light;
  }

  &--warning {
    @apply text-warning bg-warning-light;
  }

  &--error {
    @apply text-error bg-error-light;
  }
}
