/* En tamaños inferiores a laptop, los textos reducen su tamaño un 20% */

.childspan-primary > span {
  @apply text-primary;
}
.childspan-secondary > span {
  @apply text-secondary;
}
.childspan-tertiary > span {
  @apply text-tertiary;
}

.childspan-success > span {
  @apply text-success;
}
.childspan-warning > span {
  @apply text-warning;
}
.childspan-error > span {
  @apply text-error;
}
