/* font sizes
  14px, 16px, 18ps, 20px, 24px, 34px, 40px, 48px, 52px, 80px
*/

.childspan-primary > span {
  @apply text-primary;
}
.childspan-secondary > span {
  @apply text-secondary;
}
.childspan-tertiary > span {
  @apply text-tertiary;
}
.childspan-success > span {
  @apply text-success;
}
.childspan-warning > span {
  @apply text-warning;
}
.childspan-error > span {
  @apply text-error;
}
